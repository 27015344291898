<template>
  <div>
    <v-layout row justify-left align-center>
      <v-flex xs8 sm8 md8 lg8 box pa8>
        <v-select
          label="Country"
          :items="countryList"
          item-text="name"
          item-value="value"
          type="text"
          :error="error"
          :success="success"
          v-model="newCountry"
          :menu-props="{ offsetY: true }"
        ></v-select>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn
          v-if="
            this.$store.getters.permissionTo('admin/account_country', 'update')
          "
          :disabled="disableUpdate"
          @click="confirm"
          >Update</v-btn
        >
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="error" class="has-error">report_problem</v-icon>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1>
        <v-icon v-if="success">check_circle_outline</v-icon>
      </v-flex>
    </v-layout>
    <confirm-dialogue ref="confirm"></confirm-dialogue>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';
  import CountryList from './utility/countries.json';

  export default {
    name: 'CountryForm',
    data() {
      return {
        newCountry: '',
        initialCountry: '',
        countryList: CountryList['countries'],
        error: false,
        success: false,
      };
    },
    mounted() {
      // Explicitly set the values on mount
      this.newCountry = this.getCountry;
      this.initialCountry = this.getCountry;
    },
    watch: {
      getCountry: function () {
        this.newCountry = this.getCountry;
        this.initialCountry = this.getCountry;
      },
    },
    methods: {
      confirm() {
        this.$refs.confirm
          .open(
            'Update Country',
            "Are you sure you want to update this account's country?"
          )
          .then((confirm) => {
            if (confirm) {
              this.submit();
            } else {
              this.newCountry = this.getCountry;
            }
          });
      },
      submit() {
        axios
          .put(
            this.$store.getters.serviceURL +
              '/admin/accounts/account/' +
              this.accountID +
              '/countryCode',
            { newCountryCode: this.newCountry },
            this.$store.getters.bearerAuthHeaders
          )
          .then(() => {
            this.success = true;
            this.error = false;
            this.initialCountry = this.newCountry;
          })
          .catch((err) => {
            this.error = err;
            this.success = false;
            this.newCountry = this.initialCountry;
          });
      },
    },
    computed: {
      accountID() {
        return this.$store.getters.account.accountID;
      },
      getCountry() {
        return this.$store.getters.userProfile.countryCode;
      },
      domainID() {
        return this.$store.getters.account.domainID;
      },
      disableUpdate() {
        return this.initialCountry == this.newCountry;
      },
    },
  };
</script>
